<template>
    <div>
        <div>
            <b-form @submit="onSubmit" @reset="onReset">
                <b-card
                    title="Criterii de filtrare: "
                    bg-variant="light"
                    header-border-variant="dark"
                    border-variant="dark"
                >
                    <template #header>
                        <b-form-row>
                            <b-col cols="12" sm="6" lg="3">
                                <b-form-input
                                    id="input-1"
                                    v-model="form.searchkey"
                                    type="text"
                                    required
                                    :state="nameState"
                                    placeholder="Introdu cuvant de cautare"
                                ></b-form-input>
                                <b-form-invalid-feedback id="input-live-feedback">
                                    Introduceti cel putin 2 carcatere.
                                </b-form-invalid-feedback>
                            </b-col>
                        </b-form-row>
                    </template>
                    <b-form-row>
                        <b-col>
                            <b-form-group id="input-group-4">
                                <b-form-radio-group v-model="form.checked" id="radioes-4">
                                    <b-form-radio value="">Toate</b-form-radio>
                                    <b-form-radio value="subject">Subiect</b-form-radio>
                                    <b-form-radio value="body">Continut</b-form-radio>
                                    <b-form-radio value="number">Nr. tichet</b-form-radio>
                                    <b-form-radio value="spam">Spam</b-form-radio>
                                </b-form-radio-group>
                            </b-form-group>
                        </b-col>
                    </b-form-row>

                    <b-form-row>
                        <b-col cols="12" sm="6" lg="3">
                            <b-form-group
                                v-if="departments"
                                id="input-group-3"
                                label="Departament:"
                                label-for="input-3"
                            >
                                <b-form-select
                                    id="input-3"
                                    v-model="form.department"
                                    :options="departments"
                                ></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" sm="6" lg="3">
                            <b-form-group
                                v-if="agents"
                                id="input-group-5"
                                label="Agent:"
                                label-for="input-5"
                            >
                                <b-form-select
                                    id="input-5"
                                    v-model="form.agent"
                                    :options="agents"
                                ></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" sm="6" lg="6">
                            <b-form-group
                                v-if="agents"
                                id="input-group-6"
                                label="Perioada:"
                                label-for="input-6"
                            >
                                <v-menu
                                    ref="menu"
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    :return-value.sync="date"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="computedDateFormatted"
                                            label="Alege interval"
                                            prepend-inner-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            solo
                                            dense
                                            hide-details
                                            flat
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date"
                                        range
                                        :max="maxV"
                                        :min="minV"
                                        no-title
                                        scrollable
                                        locale="ro"
                                        flat
                                    >
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="menu = false">
                                            Cancel
                                        </v-btn>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="$refs.menu.save(date)"
                                        >
                                            OK
                                        </v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </b-form-group>
                        </b-col>
                    </b-form-row>

                    <b-button type="submit" variant="primary" class="mr-1"
                        >Cauta</b-button
                    >
                    <b-button type="reset" variant="danger">Reset</b-button>
                </b-card>
            </b-form>
            <!-- <b-card class="mt-3" header="Form Data Result">
        <pre class="m-0">{{ form }}</pre>
      </b-card> -->
        </div>
        <br />
        <v-app style="min-height: auto" id="search-form">
            <v-card>
                <v-card-title>
                    Rezultate cautare
                    <v-spacer></v-spacer>
                </v-card-title>
                <v-data-table
                    :page="page"
                    :pageCount="numberOfPages"
                    :headers="headers"
                    :items="mails"
                    mobile-breakpoint="768"
                    :options.sync="options"
                    :server-items-length="totalMails"
                    :loading="loading"
                    loading-text="Se incarca mail-uri... "
                >
                    <template v-slot:item.customer.email="{ item }">
                        <ConversationLink :item="item" :linktext="item.customer.email" />
                    </template>

                    <template v-slot:item.statusName="{ item }">
                        <v-chip :color="statusColor[item.status]" dark x-small>
                            {{ item.statusName }}
                        </v-chip>
                    </template>

                    <template v-slot:item.createdDate="{ item }">
                        <!-- <Date :date="item.createdDate" /> -->
                        {{ formatDate(item.createdDate) }}
                    </template>
                    <template v-slot:item.updatedAt="{ item }">
                        <!-- <Date :date="item.updatedAt" /> -->
                        {{ formatDate(item.updatedAt, true) }}
                    </template>
                    <template v-slot:no-data>
                        <div v-if="noSearch" class="text-primary">
                            Deocamdata nu ati cautat nimic
                        </div>
                        <div v-else class="text-primary">Nu exista mail-uri</div>
                    </template>
                    <template v-slot:loading>
                        <div class="text-danger">
                            Se incarca conversatii...<b-icon
                                icon="arrow-clockwise"
                                animation="spin-pulse"
                            ></b-icon>
                        </div>
                    </template>
                </v-data-table>
            </v-card>
        </v-app>
    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapActions, mapGetters } from "vuex";
// import Date from "@/view/content/Date.vue";
import moment from "moment";
import ConversationLink from "@/view/pages/conversation/ConversationLink";

export default {
    data() {
        const now = new Date();
        const maxDate =
            now.getFullYear() + "-" + (now.getMonth() + 1) + "-" + now.getDate();
        const after = new Date(maxDate);
        after.setMonth(after.getMonth() - 2);
        const minDate =
            after.getFullYear() + "-" + (after.getMonth() + 1) + "-" + after.getDate();

        return {
            loading: false,
            itemsPerPage: 25,
            page: 1,
            totalMails: 0,
            numberOfPages: 0,
            options: {
                itemsPerPage: 10,
            },
            form: {
                searchkey: "",
                checked: "",
                department: null,
                agent: null,
                after: minDate,
                before: maxDate,
            },
            show: true,
            search: "",
            date: [minDate, maxDate],
            menu: false,
            maxV: new Date().toISOString().slice(0, 10),
            minV: "",
            headers: [
                {
                    text: "Nr.",
                    align: "left",
                    sortable: false,
                    value: "number",
                    class: "h6 grey lighten-2",
                },
                {
                    text: "Email client",
                    align: "left",
                    sortable: false,
                    value: "customer.email",
                    class: "h6 grey lighten-2",
                },
                {
                    text: "Subiect",
                    value: "subject",
                    sortable: false,
                    class: "h6 grey lighten-2",
                },
                {
                    text: "Departament",
                    value: "departament",
                    sortable: false,
                    class: "h6 grey lighten-2",
                },
                {
                    text: "Agent",
                    value: "assignee.firstName",
                    sortable: false,
                    class: "h6 grey lighten-2",
                },
                {
                    text: "Status",
                    value: "statusName",
                    sortable: false,
                    class: "h5 grey lighten-2",
                },
                { text: "Data", value: "createdDate", class: "h5 grey lighten-2" },
                {
                    text: "Data ultimei modificare",
                    value: "updatedAt",
                    class: "h6 grey lighten-2",
                },
            ],
            spamItem: null,
            statusColor: {
                spam: "red",
                active: "#f3c200",
                open: "#8950FC",
                pending: "primary",
                closed: "#1BC5BD",
            },
            noSearch: true,
        };
    },
    watch: {
        date() {
            if (this.date.length === 1) {
                let minValue = new Date(this.date[0]);
                minValue.setMonth(minValue.getMonth() - 2);
                this.minV = minValue.toISOString().slice(0, 10);
                let maxValue = new Date(this.date[0]);
                maxValue.setMonth(maxValue.getMonth() + 2);
                const now = new Date();
                this.maxV =
                    now > maxValue
                        ? maxValue.toISOString().slice(0, 10)
                        : now.toISOString().slice(0, 10);
            }
        },
    },
    components: {
        // Date,
        ConversationLink,
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [{ title: "Cautare avansata" }]);
        // this.spamItem = this.config.email_statuses.find((elem) => elem.status === "spam");
    },
    watch: {
        options: {
            handler() {
                if (!this.noSearch) this.getData();
            },
            deep: true,
        },
    },
    computed: {
        ...mapGetters("mails", ["config"]),
        mails() {
            return this.$store.getters["mails/searchEmails"];
        },
        departments() {
            const departments = this.$store.getters["departments/departments"];
            let eachDepartmentOptions = [
                { value: null, text: "Filtreaza dupa departament*" },
            ];
            let onlyDeps = null;
            departments.forEach((dep) => {
                eachDepartmentOptions.push({ value: dep.id, text: dep.name });
            });
            return eachDepartmentOptions;
        },
        agents() {
            const onlyDeps = this.$store.getters["departments/agents"];
            let userNames = [{ value: null, text: "Filtreaza dupa agent" }];
            onlyDeps.forEach((user) => {
                userNames.push({ value: user.id, text: user.firstName });
            });
            return userNames;
        },
        nameState() {
            if (this.form.searchkey) {
                return this.isValid(this.name);
            }
            return null;
        },
        computedDateFormatted() {
            let min = new Date(this.date[0]);
            let max = new Date(this.date[1]);
            return this.date
                ? moment(min).locale("ro").format("dddd, Do MMMM YYYY") +
                      " - " +
                      moment(max).locale("ro").format("dddd, Do MMMM YYYY")
                : "";
        },
    },
    methods: {
        onSubmit(evt) {
            this.loading = true;
            this.noSearch = false;

            evt.preventDefault();

            if (!this.isValid()) {
                this.loading = false;
                return false;
            }

            if (this.options.page !== 1) {
                this.options.page = 1;
            }

            let payload = {
                search: this.form.searchkey,
                after: this.date[0],
                before: this.date[1],
                perPage: this.options.itemsPerPage,
                page: this.options.page,
            };

            if (this.form.checked !== "") {
                if (this.form.checked === "spam") {
                    payload.status = 4;
                } else payload[this.form.checked] = this.form.searchkey;
            }

            // if (this.form.checked.length !== 0) {
            //   this.form.checked.forEach((element) => {
            //     console.log(this.spamItem);
            //     if (element === "spam") {
            //       payload.status = this.spamItem.id;
            //     } else payload[element] = this.form.searchkey;
            //   });
            // }

            if (this.form.department) {
                payload.mailbox = this.form.department;
            }

            if (this.form.agent) {
                payload.assigned = this.form.agent;
            }

            this.$store.dispatch("mails/advancedSearch", payload).then((response) => {
                // console.log(response);
                if (response.conversations.length) {
                    this.loading = false;
                    this.totalMails = response.page.totalElements;
                    this.numberOfPages = response.page.totalPages;
                } else {
                    setTimeout(() => {
                        this.loading = false;
                    }, 500);
                }
            });
            // alert(JSON.stringify(this.form));
        },
        onReset() {
            this.$store.dispatch("mails/resetAdvancedSearch");
            // Reset our form values
            this.form.searchkey = "";
            this.form.checked = "";
            this.form.department = null;
            this.form.agent = null;
            this.date = [this.form.after, this.form.before];
            // Trick to reset/clear native browser form validation state
            this.show = false;
            this.$nextTick(() => {
                this.show = true;
            });
        },
        getData() {
            this.loading = true;

            let payload = {
                search: this.form.searchkey,
                after: this.date[0],
                before: this.date[1],
                perPage: this.options.itemsPerPage,
                page: this.options.page,
            };

            if (this.form.checked !== "") {
                if (this.form.checked === "spam") {
                    payload.status = 4;
                } else payload[this.form.checked] = this.form.searchkey;
            }

            if (this.form.department) {
                payload.mailbox = this.form.department;
            }

            if (this.form.agent) {
                payload.assigned = this.form.agent;
            }

            this.$store.dispatch("mails/advancedSearch", payload).then((response) => {
                // console.log(response);
                if (response.conversations.length) {
                    this.loading = false;
                    this.totalMails = response.page.totalElements;
                    this.numberOfPages = response.page.totalPages;
                } else {
                    setTimeout(() => {
                        this.loading = false;
                    }, 500);
                }
            });
        },
        isValid() {
            return this.form.searchkey.length > 1 ? true : false; //your validation criteria goes here
        },
        formatDate(date_created, time = false) {
            const date = new Date(date_created);

            return `${("0" + date.getDate()).slice(-2)}-${(
                "0" +
                (date.getMonth() + 1)
            ).slice(-2)} ${date.getFullYear()} ${
                time ? date.getHours() + ":" + ("0" + date.getMinutes()).slice(-2) : ""
            }`;
        },
        formatDate2(date) {
            if (!date) return null;

            const [year, month, day] = date.split("-");
            return `${day}/${month}/${year}`;
        },
    },
    created() {
        this.$store.dispatch("departments/getDepartments");
    },
    destroyed() {
        this.onReset();
    },
};
</script>

<style>
#search-form tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
}
</style>
